export default [
  {
    path: 'home',
    label: 'Accueil',
  },
  {
    path: 'feature',
    label: 'Fonctionalités',
  },
  {
    path: 'testimonial',
    label: 'Témoignages',
  },
  {
    path: 'pricing',
    label: 'Tarification',
  },
];
