export default [
  {
    header: 'Nos informations',
    items: [
      {
        path: '/',
        label: 'Plan du site',
      },
      {
        path: '/',
        label: 'Heures de services',
      },
    ],
  },
  {
    header: 'Nos politiques',
    items: [
      {
        path: '/',
        label: 'Politique de remboursement',
      },
      {
        path: '/privacy-policy',
        label: 'Politique de confidentialité',
      },
      {
        path: '/terms-and-conditions',
        label: "Termes et conditions",
      },
      {
        path: '/data-deletion',
        label: "Suppression des données",
      },
    ],
  },
  {
    header: 'A propos',
    items: [
      {
        path: '/',
        label: "Centre d'aide",
      },
      {
        path: '/',
        label: "Service client",
      },
      {
        path: '/',
        label: 'A propos de nous',
      },
      {
        path: '/',
        label: "Droits d'auteurs",
      },
    ],
  }
];
